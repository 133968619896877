var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"tourism color-primary page_bg_grey h-100 min-h-100vh blank-aside-js",class:_vm.isOpenAside == 'true' ? 'blank-aside' : ''},[_c('TourismFilter',{attrs:{"hasTypeDeal":false},on:{"handleFilter":_vm.handleFilter}}),_c('BannerSection',{attrs:{"location":"homepage-top"}}),_c('div',{staticClass:"tourism-container mt-15"},[_c('h2',{staticClass:"d-flex align-items-center justify-content-between title title__h2 tourism__title mb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("Cheapest flights")))]),(_vm.isShowSlide)?_c('p',{staticClass:"weight-400 color-primary fs16 pointer mb-0",on:{"click":_vm.handleViewAll}},[_vm._v(_vm._s(_vm.$t( "View all Flights" )))]):_c('SortTourism',{attrs:{"isTourismDeal":false},on:{"sortDeals":_vm.sortDeals}})],1),(_vm.isShowSlide)?_c('div',{staticClass:"mt-5"},[_c('VueSlickCarousel',{staticClass:"cus-slick-carousel tourism-carousel",class:_vm.cheapestDealsData.length<4 ? _vm.sliderDirection : '',attrs:{"slidesToShow":_vm.itemPerSlide,"rtl":_vm.sliderDirection=='rtl' ? true : false}},_vm._l((_vm.cheapestDealsData),function(deal,index){return (deal.dealType == 'CHARTER')?_c('div',{key:index,staticClass:"px-2"},[_c('FlightTourismItem',{attrs:{"deal":deal}},[_c('button',{staticClass:"primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7",attrs:{"slot":"actionPart"},on:{"click":function($event){return _vm.openFullDetailModal2(deal)}},slot:"actionPart"},[_c('img',{attrs:{"src":"/assets/img/icons/info-white.svg","alt":""}}),_vm._v(" "+_vm._s(_vm.$t( "Details" ))+" ")]),_c('router-link',{attrs:{"slot":"routerBlock","to":{
                name: 'FlightAll',
                query: {
                  to: deal.destination_id, 
                  start_date: _vm.formatDate(_vm.dateRange.startDate),
                  end_date: _vm.formatDate(_vm.dateRange.endDate),
                  passengers: ''
                }
              }},slot:"routerBlock"},[_c('button',{staticClass:"primary-btn small-btn fs16 bg-3"},[_vm._v(" "+_vm._s(_vm.$t( "Explore more Dates" ))+" ")])])],1)],1):_vm._e()}),0)],1):_c('div',[_c('div',{staticClass:"row mt-2"},_vm._l((_vm.oneTypeCheapestDeals),function(deal,index){return (deal.dealType == 'CHARTER')?_c('div',{key:index,staticClass:"col-md-3"},[_c('FlightTourismItem',{attrs:{"deal":deal}},[_c('button',{staticClass:"primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7",attrs:{"slot":"actionPart"},on:{"click":function($event){return _vm.openFullDetailModal2(deal)}},slot:"actionPart"},[_c('img',{attrs:{"src":"/assets/img/icons/info-white.svg","alt":""}}),_vm._v(" "+_vm._s(_vm.$t( "Details" ))+" ")]),_c('router-link',{attrs:{"slot":"routerBlock","to":{
                name: 'FlightAll',
                query: {
                  to: deal.destination_id, 
                  start_date: _vm.formatDate(_vm.dateRange.startDate),
                  end_date: _vm.formatDate(_vm.dateRange.endDate),
                  passengers: ''
                }
              }},slot:"routerBlock"},[_c('button',{staticClass:"primary-btn small-btn fs16 bg-3"},[_vm._v(" "+_vm._s(_vm.$t( "Explore more Dates" ))+" ")])])],1)],1):_vm._e()}),0)])]),_c('FullDetailModal2',{ref:"fullDetailModal2Ref"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }