<template>
  <main
    class="tourism color-primary page_bg_grey h-100 min-h-100vh blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  > 
    <TourismFilter :hasTypeDeal="false" @handleFilter="handleFilter"></TourismFilter>
    <BannerSection location="homepage-top"></BannerSection>
    <div class="tourism-container mt-15">
      <h2 class="d-flex align-items-center justify-content-between title title__h2 tourism__title mb-0">
        <p class="mb-0">{{ $t("Cheapest flights") }}</p>
        <p class="weight-400 color-primary fs16 pointer mb-0" @click="handleViewAll" v-if="isShowSlide">{{ $t( "View all Flights" ) }}</p>
        <SortTourism @sortDeals="sortDeals" :isTourismDeal="false" v-else></SortTourism>
        <!-- <router-link
          :to="{
            name: 'FlightAll',
          }"
          slot="routerBlock" 
        >
          <p class="weight-400 color-primary fs16">{{ $t( "View all Flights" ) }}</p>
        </router-link> -->
      </h2>
      <div v-if="isShowSlide" class="mt-5">
        <VueSlickCarousel
          v-bind:slidesToShow="itemPerSlide"
          class="cus-slick-carousel tourism-carousel"
          :class="cheapestDealsData.length<4 ? sliderDirection : ''"
          :rtl="sliderDirection=='rtl' ? true : false"
        >
          <div class="px-2" 
            v-for="(deal, index) in cheapestDealsData"
            :key="index"
            v-if="deal.dealType == 'CHARTER'"
          >
            <FlightTourismItem :deal="deal">
              <!-- <p class="fs14 text-center" slot="title">
                Flights to <b>{{ deal.dealDestination }}</b>
              </p> -->
              <button 
                class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
                slot="actionPart" 
                @click="openFullDetailModal2(deal)"
              >
                <img src="/assets/img/icons/info-white.svg" alt="" class="">
                {{ $t( "Details" ) }}
              </button>
              <router-link
                :to="{
                  name: 'FlightAll',
                  query: {
                    to: deal.destination_id, 
                    start_date: formatDate(dateRange.startDate),
                    end_date: formatDate(dateRange.endDate),
                    passengers: ''
                  }
                }"
                slot="routerBlock" 
              >
                <button class="primary-btn small-btn fs16 bg-3">
                  {{ $t( "Explore more Dates" ) }}
                </button>
              </router-link>
            </FlightTourismItem>
          </div>
        </VueSlickCarousel>
      </div>
      <div v-else>
        <div class="row mt-2">
          <div class="col-md-3" 
            v-for="(deal, index) in oneTypeCheapestDeals"
            :key="index"
            v-if="deal.dealType == 'CHARTER'"
          >
            <FlightTourismItem :deal="deal">
              <!-- <p class="fs14 text-center" slot="title">
                Flights to <b>{{ deal.dealDestination }}</b>
              </p> -->
              <button 
                class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
                slot="actionPart" 
                @click="openFullDetailModal2(deal)"
              >
                <img src="/assets/img/icons/info-white.svg" alt="" class="">
                {{ $t( "Details" ) }}
              </button>
              <!-- <button slot="routerBlock" class="primary-btn small-btn fs16 bg-3" @click="handleClickDeal(deal)" >
                  {{ $t( "Explore more Dates" ) }}
              </button> -->
              <router-link
                :to="{
                  name: 'FlightAll',
                  query: {
                    to: deal.destination_id, 
                    start_date: formatDate(dateRange.startDate),
                    end_date: formatDate(dateRange.endDate),
                    passengers: ''
                  }
                }"
                slot="routerBlock" 
              >
                <button class="primary-btn small-btn fs16 bg-3">
                  {{ $t( "Explore more Dates" ) }}
                </button>
              </router-link>
            </FlightTourismItem>
          </div>
        </div>
      </div>

    </div>


    <!-- <div class="tourism-container mt-15">
      <h2 class="d-flex align-items-center justify-content-between title title__h2 tourism__title">
        <p>{{ $t( "Last minute flights" ) }}</p>
        <router-link
          :to="{
            name: 'FlightAll',
          }"
          slot="routerBlock" 
        >
          <p class="weight-400 color-primary fs16">{{ $t( "View all Flights" ) }}</p>
        </router-link>
      </h2>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px-5" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem :deal="deal">
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal2()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "Explore more Dates" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
    </div> -->
    <FullDetailModal2 ref="fullDetailModal2Ref"></FullDetailModal2>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import tourismMixin from '@/utility/tourism_mixin'
import BannerSection from "../../../components/BannerSection";
import TourismFilter from "../../../components/tourism/TourismFilter.vue";
import FlightTourismItem from "../../../components/tourism/flight/FlightTourismItem.vue";
import FullDetailModal2 from "../../../components/tourism/flight/FullDetailModal2.vue";
import SortTourism from "@/components/tourism/SortTourism"

export default {
  name: "FligntHome",
  mixins: [tourismMixin],
  components: {
    BannerSection,
    TourismFilter,
    FlightTourismItem,
    FullDetailModal2,
    SortTourism
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    return {
      oneTypeCheapestDeals: [],
      isShowSlide: true,
      dateRange: { 
        startDate: startDate, 
        endDate: endDate, 
      },
      // deals: [{},{},{},{},{},{},{},{},{},{},{},{}]
    }
  },
  async created() {
    if (this.cheapestDealsData.length == 0) {
      await this.getCheapestDealsAll();
    } 
    this.oneTypeCheapestDeals = this.cheapestDealsData;
  },
  computed: {
    ...mapState({
      cheapestDealsData: (state) => state.deal.cheapestDealsAllData
    }),
  },
  methods: {
    ...mapActions("deal", {
      getCheapestDealsAll: "getCheapestDealsAll"
    }),
    openFullDetailModal2(deal) {
      this.$refs.fullDetailModal2Ref.openModal(deal);
    },
    handleViewAll() {
      this.isShowSlide = false;
    },
    handleFilter() {
      
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/tourism.scss";
.rtl-type .cus-slick-carousel .full-detail-btn {
  direction: rtl !important;
}

</style>
